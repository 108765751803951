.input-group {
    display: flex;
    align-items: center;
  }

  .star-rating {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .star-rating-input {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2rem;
    height: 2rem;
    font-size: 1.5rem; /* Adjust the size as needed */
    color: #ffd700;

    margin-top: 1rem;
  }
  
  .star-rating-value {
    position: absolute;
    font-size: 1rem;
    color: #000;
    font-weight: bold;
  }

  #decreaseRating {
    margin-right: 2.5rem;
  }

  #increaseRating {
    margin-left: 2.5rem;
  }

