  .map-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 90%;
    height: 20vh;
    margin: 0 auto;
    position: relative;
    z-index: 1;
  }
  
  
  .map-container {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;
  }
  
  @media (min-width: 576px) {
    .map-wrapper {
      max-width: 60%;
    }
  }
   
  