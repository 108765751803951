.latest-movie-review {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
  
    margin: 0 auto;
    background-color: transparent !important; /* Changed the background color to transparent */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Ensures that the movie review does not overflow its container */
    word-wrap: break-word; /* Prevent long words from overflowing */
  }
  
  
  .latest-movie-name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #ff9900; /* Changed the text color to a shade of orange */
  }
  
  .latest-review-text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    color: #fff; /* Changed the text color to white */
    white-space: pre-wrap;
  }
  
  .latest-user-name {
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 0.5rem;
    color: #ff9900; /* Changed the text color to a shade of orange */
  }
  
  .latest-rating {
    display: flex;
  }
  
  .latest-review-date {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
    color: #999; /* Changed the text color to a lighter shade of gray */
  }
  
  .full-star,
  .half-star {
    color: gold;
  }

  .blogPage {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
  }
  
  .blogPage-header {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .blogPage-addBtn {
    display: block;
    margin: 0 auto 20px;
  }
  
  hr {
    border: none;
    border-top: 2px solid #aaa;
    margin-bottom: 20px;
  }
  
  .blogPage-post {
    background-color: #000000;
    border: 1px solid #dee2e6;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .blogPage-postTitle {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .blogPage-title {
    width: 80%;
    height: 80%;
    margin-bottom: 25px;
  }
  
  .blogPage-postDetails {
    font-size: 0.9rem;
    color: #777;
  }
  
  .blogPage-postAuthor {
    margin-right: 10px;
  }

  .blogPage .ql-container {
    width: 100% !important;
  }
  
  .blogPage .ql-toolbar {
    width: 100%;
  }
  
  .no-underline {
    text-decoration: none;
    color: inherit;
  }

  .no-underline:hover {
    color: inherit; /* If this doesn't work, change 'inherit' to the desired color, e.g., 'black' or '#333' */
  }

  
  