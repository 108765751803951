body .modal-content {
    background-color: #181818; /* Adjust the color to your preference */
  }

  .modal-dialog {
    background-color: #3a3a3a(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .modal-title {
    color:white;
  }

.movieReviewAdd {
    background-color: #3a3a3a(30, 30, 30, 0.8);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 1600px !important;
    margin: 1rem auto;
  }
  
  .movieReviewAdd form {
    display: flex;
    flex-direction: column;
  }
  
  .movieReviewAdd form label {
    font-weight: bold;
    margin-top: 10px;
    color: #fff;

  }
  
  .movieReviewAdd form input,
  .movieReviewAdd form textarea,
  .movieReviewAdd form textarea,
  .movieReviewAdd form select {
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(22, 21, 21);
    color: white;
  }

  .movieReviewAdd form button {
    margin-top: 20px;
    background-color: #040404;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 1rem;
  }
  
  .movie-review-add form button:hover {
    background-color: #151313;
  }

  .modal-header .btn-close {
    background-color: white;
    color: white;
  }

  .error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .movieReviewAdd .react-autosuggest__input {
    width: 100%;
    background-color: rgb(22, 21, 21);
    color: white;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .movieReviewAdd .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  
  .movieReviewAdd .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 5px 10px;
    background-color: white;
    color: black;
  }
  
  .movieReviewAdd .react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
    color: black;
  }
  