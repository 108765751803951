.login-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .login-box {
    background-color: rgba(30, 30, 30, 0.3); /* Change the alpha value (0.8) for the desired transparency */
    border-radius: 5px;
    padding: 2rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 400px;
  }
  
  .login-box h3 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .login-box form {
    display: flex;
    flex-direction: column;
  }
  
  .login-box label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .login-box input {
    font-size: 1.2rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    outline: none;
    border: 1px solid #d32f2f;
    border-radius: 5px;
  }
  
  .login-box button {
    font-size: 1.2rem;
    padding: 0.5rem;
    cursor: pointer;
    background-color: #d32f2f;
    color: #fff;
    border: none;
    margin-bottom: 1rem;
  }