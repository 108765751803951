.all-movies-option {
    font-weight: bold; /* Make the text bold */
    color: white; /* Set the text color */
  }
  
  .filter-dropdown {
    width: 100%;
    max-width: 300px;
    margin-bottom: 10px;
    background-color: rgb(108, 29, 29);
    color: white !important;
  }