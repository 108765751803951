.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5); /* This creates a semi-transparent black background */
    z-index: 1000;
}

.modal-content {
    /*width: 300px !important;*/
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    text-align: center;
}

.modal-content button {
    display: block;
    margin-top: 20px;
    padding: 10px;
    border: none;
    background-color: #007BFF;
    color: #ffffff;
    border-radius: 5px;
    cursor: pointer;
}
