.post-list-wrapper {
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .post-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
    position: relative;
    
  }
  
  .post-list {
    position: relative;
  }
  
  .post {
    display: flex;
    border: 1px solid #ccc;
    padding: 0;
    margin-bottom: 10px;
    position: relative;
    background-color: #222831;
  }
  
  .user-info {
    padding: 15px;
    border-right: 1px solid #ccc;
    text-align: center;
  }
  
  .profile-pic {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 50%;
    margin-bottom: 5px;
  }
  
  .username {
    font-size: 14px;
    font-weight: bold;
  }
  
  .post-details {
    flex: 1;
    padding: 15px;
  }
  
  .post-header {
    color: #fff;
    padding: 5px 10px;
    border-bottom: 1px solid #ccc;
    margin-bottom: 10px;
  }
  
  .post-date {
    font-size: 12px;
    margin-left: 10px;
  }
  
  .top-level {
    border-color: #ccc;
  }
  
  .reply-level {
    border-color: #ccc;
  }
  
  .post-content {
    padding: 0;
    margin-left: 10px;
    margin-bottom: 30px;
    white-space: pre-wrap;
  }
  
  .reply-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    cursor: pointer;
  }
  
  .reply-form {
    display: none;
    margin-top: 10px;
  }
  
  .reply-form.active {
    display: block;
  }
  
  .create-post-button {
    background-color: #007bff;
    border: none;
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
  }
  .back-button {
    
    border: none;
    color: #007bff;
    font-size: 24px !important;
    cursor: pointer;
  }

  .post-form-buttons {
    display: inline-flex;
    margin-top: 1rem;
  }
  .cancel-button-wrapper {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .create-post-form {
    max-width: 1200px;
  }

  .like-button {
    display: inline-block;
    cursor: pointer;
    margin-left: 5px;
    padding-right: 5px;
}

.like-button:hover {
    background-color: gray;
}

.like-counter {
  position: relative;
}

.likes-dropdown {
  position: absolute;
  width: 200px;
  background: rgb(5, 5, 52);
  color: white;
  border: 2px solid white;
  padding: 10px;
  z-index: 1;
}


  
  