.restaurants-container {
    background-color: #222;
    padding: 2rem;
    min-height: 1200px;
  }

  
  .restaurant-item {
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 10px;
    background-color: #333 !important;
    padding: 1rem;
    position: relative;
    max-width: 95%; 
    margin: 0 auto;
    overflow-x: hidden;
    justify-content: space-between;
    max-width: 100%; /* Ensures that the  review does not overflow its container */
    word-wrap: break-word; /* Prevent long words from overflowing */
    margin-bottom: 1rem;
  }
  
   
  .restaurant-name {
    font-size: 1.5rem;
    color: #FFD700;
    margin-bottom: 0;
    font-weight: bold;
  }
  
  .review-separator {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    margin-bottom: 2rem;
    margin-top: 0.5rem;
  }
  
  .review {
    font-size: 1.1rem;
    color: #FFEF99;
    margin-bottom: 2rem;
  }
  
  .restaurant-info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 1rem;
  }
  
  .restaurant-address {
    font-size: 1rem;
    font-weight: bold;
    color: #aaa;
    margin-bottom: 0;
    align-self: flex-end;
  }
  
  @media (min-width: 1700px) {
    .restaurant-info {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .review-user {
    font-style: italic;
    font-size:1rem;
    color: #bbb;
    text-align: left;
  }

  .restaurant-header {
    font-family: 'Playfair Display SC', serif;
    color: white;
    font-weight: bolder;
    font-size: 3rem; /* change the font size to your desired size */
    text-align: center; /* center the text */
    margin-top: 0.5rem; /* add some top margin to space it from the container */
    margin-bottom: 2rem;
    background-image: linear-gradient(rgba(255, 44, 44, 0.3), rgba(255, 44, 44, 0.3)), url('../../images/food.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  }

  .card {
    box-sizing: border-box; /* include padding in total width calculation */
    background-color: #333 !important;
  }

/* Add this new class for the toolbar */
.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

/* Update the .search-textbox class */
.search-textbox {
  background-color: #000;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #F1E4C0;
  padding: 0.5rem 1rem;
  width: 100%;
  max-width: 300px;
  height: 2.5rem; /* Add this line */
}

.add-review-button,
.filter-button {
  background-color: red;
  color: white;
  border: 1px solid #ccc;
  font-size: 1.2rem; /* Reduce the font size */
  cursor: pointer;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  height: 2.5rem;
  display: flex; /* Add this line */
  align-items: center; /* Add this line */
  justify-content: center; /* Add this line */
  text-align: center; /* Add this line */
}

.add-review-button:hover,
.filter-button:hover {
  background-color: #cc0000;
  border-color: white;
}

.add-review-button.disabled {
  background-color: #ccc;
  color: #888;
  cursor: not-allowed;
}

/* Restaurants.css */

@media (max-width: 767px) {
/*   .add-review-button {
    font-size: 14px;
  }
 */
  .restaurant-name {
    font-size: 18px;
  }
}


  
  