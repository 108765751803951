.create-thread {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #222;
    color: #fff;
    border-radius: 5px;
  }
  
  .create-thread h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-thread form {
    display: flex;
    flex-direction: column;
  }
  
  .create-thread label {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .create-thread input,
  .create-thread textarea {
    margin-top: 5px;
    padding: 5px;
    border: 1px solid #444;
    border-radius: 3px;
    background-color: #333;
    color: #fff;
  }
  
  .create-thread textarea {
    min-height: 200px;
    resize: vertical;
  }
  
  .create-thread button {
    margin-top: 10px;
    padding: 10px;
    
    color: #fff;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .create-thread button:hover {
    background-color: #3f9142;
  }
  