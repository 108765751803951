.user-profile-container {
    margin-top: 2rem;
  }
  
  .user-profile-title {
    text-align: center;
  }
  
  /* UserProfile.css */
.profile-picture {
    border-radius: 50%;
    width: 2.5rem;
    height: 2.5rem;
    object-fit: cover;
  }
  
  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    border: 2px dashed #ccc;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .upload-container:hover {
    border-color: #999;
  }
  
  .upload-icon {
    font-size: 2rem;
    color: #999;
  }
  
  .upload-text {
    margin-top: 1rem;
    color: #999;
  }
  
  .userProfileForm {
    background-color: rgba(30, 30, 30, 0.8);
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: 1rem auto;
  }
  
  .userProfileForm form {
    display: flex;
    flex-direction: column;
  }
  
  .userProfileForm form label {
    font-weight: bold;
    margin-top: 10px;
    color: #fff;
  }
  
  .userProfileForm form input {
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(22, 21, 21);
    color: white;
  }
  
  .userProfileForm form button {
    margin-top: 20px;
    background-color: #040404;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 1rem;
  }
  
  .userProfileForm form button:hover {
    background-color: #151313;
  }

  .custom-dropdown-container {
    position: relative;
  }
  
  .custom-dropdown-container:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #fff;
    transform: translateY(-50%);
    pointer-events: none;
  }

  .user-update-container form select {
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(22, 21, 21);
    color: white;
  }
  .image-container {
    position: relative;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
  }
  
  
  

  