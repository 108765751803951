/* New CSS for SignUp form */
.signup-container {
    background-color: #181818;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px !important;
    margin: 3rem auto;
  }
  
  .signup-container form {
    display: flex;
    flex-direction: column;
  }
  
  .signup-container form label {
    font-weight: bold;
    margin-top: 10px;
    color: #fff;
  }
  
  .signup-container form input,
  .signup-container form textarea,
  .signup-container form select {
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 5px;
    background-color: rgb(22, 21, 21);
    color: white;
  }
  
  .signup-container form button {
    margin-top: 20px;
    background-color: #040404;
    color: #fff;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-weight: bold;
    border-radius: 5px;
    margin-top: 1rem;
  }
  
  .signup-container form button:hover {
    background-color: #151313;
  }
  
  .signup-container .error-message {
    color: red;
    font-size: 0.8rem;
    margin-top: -0.5rem;
    margin-bottom: 0.5rem;
  }

  .signup-title { 
    color: white;
  }

  .validation-placeholder {
    height: 15px;
    display: flex;
    align-items: center;
  }
  
  .validation-error {
    color: #dc3545;
    font-size: 0.875em;
  }
  .no-validation-spacing {
    margin-bottom: 15px;
  }

  .custom-dropdown-container {
    position: relative;
  }
  
  .custom-dropdown-container:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1rem;
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 7px solid #fff;
    transform: translateY(-50%);
    pointer-events: none;
  }

  
  
  
  
  