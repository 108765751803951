.movie-review {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 1rem;
    
    margin: 0 auto;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 100%; /* Ensures that the movie review does not overflow its container */
    word-wrap: break-word; /* Prevent long words from overflowing */
  }
  
  .movie-name {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
    color: #F1E4C0 !important;
  }
  
  .review-text {
    font-size: 1rem;
    margin-bottom: 0.5rem;
    white-space: pre-wrap;
  }
  
  .user-name {
    font-size: 0.9rem;
    font-style: italic;
    margin-bottom: 0.5rem;
  }
  
  .rating {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .stars {
    display: flex;
  }

  .review-date {
    font-size: 0.8rem;
    margin-bottom: 0.5rem;
  }
  
  .full-star,
  .half-star {
    color: gold;
  }

  .filter-add-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .add-review-button {
    background-color: red;
    color: white;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }
  
  .add-review-button:hover {
    background-color: #cc0000;
  }

  .modal {
    background-color: transparent; /* Updated this line */
  }
  
  
  .trashcan-icon {
    position: absolute;
    bottom: 5px;
    right: 5px;
    font-size: 1rem;
    cursor: pointer;
    color: white !important;
  }
  
  .delete-modal .modal-content {
    background-color: red;
    color: white;
    border: 1px solid white;
  }
  
  .delete-modal .modal-dialog {
    max-width: 400px;
  }
  
  .with-custom-backdrop + .modal-backdrop.show {
    opacity: 0.2;
    background-color: rgba(0, 0, 0, 0.8); /* Adjust this value to your preference */
  }

  .fa-pen {
    margin-right: 15px;
  }

  .fa-solid.fa-pen,
.fa-solid.fa-trash-can {
  transition: color 0.3s, transform 0.3s;
  cursor: pointer;
}

.fa-solid.fa-pen:hover,
.fa-solid.fa-trash-can:hover {
  color: #ff5e5e; /* Change the color as desired */
  transform: scale(1.3); /* Change the scaling factor as desired */
}


  
  