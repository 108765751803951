.create-post {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    color: #fff;
  }
  
  .create-post h2 {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .create-post form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .create-post label {
    font-size: 16px;
  }
  
  .create-post textarea {
    resize: none;
    width: 100%;
    height: 200px;
    background-color: #333;
    color: #fff;
    border: 1px solid #555;
    padding: 10px;
    font-size: 14px;
    border-radius: 5px;
  }
  
  .create-post button {
    padding: 10px 15px;
    
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s;
  }
  
  