* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
 /*  background-image: url('images/theater3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat; */
  background-image: radial-gradient(circle at center, rgba(45, 45, 45, 0.9), rgba(20, 20, 20, 1));
  background-color: #141414;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-content {
  flex-grow: 1;
}

.App-header {
  /* background-color: #d32f2f; */
  /* background-color: #A02222; */
  background-image: url('images/midjourney.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 2rem;
}

.centered-div {
  grid-column: 2;
  display: flex;
  justify-content: center;
}

.right-justified-divs {
  grid-column: 3;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.App-header h1 {
  color: #fff;
  font-size: 2rem;
  margin: 0; /* Remove the "auto" value to prevent centering */
}

.hamburger {
  background-color: transparent;
  border: none;
  color: #fff;
  font-size: 1.5rem;
  cursor: pointer; 
  margin-left: 1rem;
}


.nav-links {
  position: fixed;
  right: 0px;
  top: 3em;
  width: 400px;
  height: 425px;
  background-color: rgba(30, 30, 30);
  display: flex;
  flex-direction: column;
  align-items: stretch; /* Change this to stretch to make buttons full width */
  justify-content: flex-start; /* Change this to flex-start to move buttons to the top */
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  z-index: 100;
   /* Add padding at the top and bottom of the popout */
}


.nav-links.show {
  transform: translateX(0%);
}

.nav-links a {
  color: #fff;
  text-decoration: none;
  font-size: 1.3rem;
  margin-bottom: 0.4rem;
  background-color: #A02222;
  border-radius: 5px;
  padding: 10px;
  
  justify-content: center; /* Add this to align text to the center */
  width: 100%; /* Set the button width to 100% */
  box-sizing: border-box; /* Add this to include padding in the button width */
}

.nav-links a:last-child {
  margin-bottom: 0;
}

.nav-links a:hover {
  background-color: #b71c1c;
}

main {
  max-width: 66%; /* Limit the content width to 50% */
  margin: 1rem auto; /* Center the content */
  color: #fff;
}

/* Mobile styles */
@media (max-width: 768px) {
  main {
    max-width: 100%; /* Use 100% of the screen width on mobile devices */
  }
}

section {
  margin-top: 50px;
}


article {
  margin-top: 20px;
}

.auth-buttons {
  display: flex;
  position: relative;
}

.auth-buttons button {
  background-color: #A02222;
  border: 1px solid #fff;
  color: #fff;
  margin-left: 1rem;
  margin-right: 0.2rem;
  padding: 5px 10px;
  cursor: pointer;
  margin-top: 1.3rem; 
}

.auth-buttons button:hover {
  background-color: #f44336;
  border-color: #f44336;
}

.banner-text {
  /* font-family: 'Great Vibes', cursive; */
  font-family: 'Film', serif;
  font-size: 3rem;
  font-weight: normal;
  /* color: #ffdbb4; */
  color: #F1E4C0;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  text-decoration: underline;
}

.default-font {
  font-family: 'Helvetica Neue', sans-serif;
}

@media (max-width: 1200px) {
  .banner-text {
    font-size: 2rem; /* or any other size you want for mobile screens */
  }
  .responsive-text {
    /* font-size: 1rem !important; */
    width: 80px !important;
  }

}

.pinned-hamburger {
  position: absolute;
  top: -15px;
  right: 5px;
}

.menu-link {
  font-size: 25px;

  color: #F1E4C0;
  text-decoration: none;
  padding: 10px 20px;
  font-weight: bolder;
}

.menu-link:hover {
  opacity: 1;
  color: white;
  background-color: rgb(45, 43, 43);
}

.menu-link[aria-current="page"] {
  color: #ffffff;
  border-bottom: 10px solid #ffffff;
}


@media (max-width: 1520px) {
  .menu-link {
    font-size: 20px;
  }
}

.user-menu {
  background-color: white !important;
  font-size: 1rem;
  position: fixed !important;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.6) !important;
  z-index: 101 !important;
  padding: 5px 90px 5px 5px;
  margin-top: 10px;
}

.user-menu a {
  display: block;
  padding: 0px 10px;
  color: black;
  text-decoration: none;
}

.user-menu a:hover {
  background-color: lightblue;
}