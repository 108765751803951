.triviaContainer {
    max-width: 600px;
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
    margin: auto;
   
  }
  
  .question {
    font-size: 1.25em;
    margin-bottom: 1em;
  }
  
  .answer {
    padding: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .hoverable:hover {
    background-color: #808080;
  }
  
  .correct {
    background-color: #006400;
  }
  
  .incorrect {
    background-color: #B22222;
  }
  
  .nextButton {
    cursor: pointer;
    margin-top: 20px;
    padding: 10px;
    background-color: #808080;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .nextButton:hover {
    background-color: #696969;
  }
  
  .finalScore {
    font-size: 1.5em;
    text-align: center;
  }
  
  /* existing CSS here... */

.seeScoreButton {
    cursor: pointer;
    margin-top: 20px;
    padding: 10px;
    background-color: #808080;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
  }
  
  .seeScoreButton:hover {
    background-color: #696969;
  }
  
  @media (max-width: 576px) { 
    .triviaContainer {
      margin-left: 10px;
      margin-right: 10px;
    }
  }