/* Style the buttons */
.rbc-btn-group button {
  background-color: #4e88ff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  border-radius: 5px;
  margin: 0 2px;
  cursor: pointer;
}

.rbc-toolbar-label {
  font-size: 1.5rem;
  font-weight: bold;
  color: #ffffff;
}


.rbc-btn-group button:hover {
  background-color: #2f62ff;
}

/* Style the calendar cells */
.rbc-day-bg {
  background-color: #444;
}

.rbc-today {
  background-color: #666;
}

.rbc-off-range-bg {
  background-color: #333;
}

/* Style the calendar events */
.rbc-event {
  background-color: #4e88ff;
  color: #fff;
  border-radius: 5px;
  padding: 2px 5px;
  cursor: pointer;
}

.rbc-event:hover {
  background-color: #2f62ff;
}

.rbc-selected {
  background-color: #2f62ff;
}



.events-container {
  background-color: black;
  color: white;
  padding: 1rem;
  font-family: Arial, sans-serif;
  
}

h1 {
  text-align: center;
  margin-bottom: 2rem;
}

.event-item {
  background-color: #333;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  padding: 1rem;
  margin-bottom: 1rem;
  margin-left: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
}

.event-title {
  font-size: 2rem;
  color: #e65100;
  margin-bottom: 0.5rem;
}

.event-date-time {
  font-weight: bold;
  color: #42a5f5;
  margin-bottom: 1rem;
}

.event-description {
  white-space: pre-wrap;
  margin-bottom: 1rem;
  flex-grow: 1;
}

.event-button {
  position: absolute;
  bottom: 1rem;
  right: 1rem;
}

.calendar-container {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}
