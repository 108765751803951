/* UserProfileIcon.module.css */


  
  .star-container {
    position: relative;
    width: 2.3rem;
    height: 2.3rem;
    margin-top: 1rem;
    border: 1px solid #fff;
    border-radius: 5px;
  }
  
  .star {
    width: 100%;
    height: 100%;
  }
  
  .star-bg {
    fill: #36454f;
  }
  
  .star-brass {
    fill: #f88379;
  }
  
  .initial {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -44%);
    font-family: Arial, sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
    color: #13110c;
    text-align: center;
  }

  .profile-picture {
    border-radius: 50%;
    width: 2.5rem !important;
    height: 2.5rem !important;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; /* Adjust to 'contain' if you want to fit the entire image inside the circle */
    margin-top: 1.0rem;
    border: 1px solid #fff;
  }

  .profile-picture-wrapper {
    display: inline-block;
    position: relative; 
  }

  .profile-picture-wrapper-arrow::after {
    content: '';
    position: absolute;
    bottom: -4px;
    right: 0;
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid #fff;
  }
  
  
  
  
  
  
  