.thread-item {
    border: 2px solid #ccc !important;
    padding: 15px;
    margin-bottom: 20px;
    background-color: #222831;
    cursor: pointer;
    padding: 1rem;
    border-radius: 5px;
  }

.thread-list {
    background-color: #222831;
    color: #fff;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .thread-list h2 {
    margin-bottom: 1rem;
  }
  

  
  .thread-item h3 {
    margin-bottom: 0.5rem;
    font-size: 1.2rem;
  }

  
  .create-thread-button:hover {
    background-color: #3f9142;
  }

  .thread-list h2,
.thread-list td {
  color: #ffffff !important;
  font-weight: bolder !important;
}
.thread-list thead th {
    color: #ffffff;
    border: 2px solid #ccc;
    background-color: rgb(6, 38, 90);
    font-style: italic;
  }
  
  .thread-list tbody tr:hover {
    cursor: pointer;
    background-color: #3C3F41;
  }
  
  .thread-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }

 
  
  .action-buttons {
    display: flex;
    gap: 0.5rem;
  }
  
  
  .back-button {
    background-color: transparent;
    border: none;
    color: white;
    font-size: 24px !important;
    cursor: pointer;
  }
  
  .create-thread-button .refresh-button {
    background-color: #007bff;
    border: none;   
    color: white;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 10px 2px;
    cursor: pointer;
    border-radius: 5px;
  }

  .pagination {
    display: flex;
    list-style: none;
    justify-content: center;
    padding: 0;
  }
  
  .pagination .previous,
  .pagination .next,
  .pagination .break,
  .pagination .page {
    margin: 0 5px;
  }
  
  .pagination .previous-link,
  .pagination .next-link,
  .pagination .break-link,
  .pagination .page-link {
    text-decoration: none;
    padding: 5px 10px;
    border: 1px solid #ccc;
    color: #333;
  }
  
  .pagination .active .page-link {
    background-color: #007bff;
    border-color: #007bff;
    color: #fff;
  }
  
  .pagination .previous-link:hover,
  .pagination .next-link:hover,
  .pagination .page-link:hover {
    background-color: #eee;
    border-color: #ccc;
    cursor: pointer;
  }
  
  .pagination .previous.disabled .previous-link,
  .pagination .next.disabled .next-link {
    cursor: not-allowed;
    opacity: 0.5;
  }

  .spinner-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
  }
  
  

  
  
  