.forgot-password-section {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .forgot-password-box {
    background-color: rgba(30, 30, 30, 0.3); /* Change the alpha value (0.8) for the desired transparency */
    border-radius: 5px;
    padding: 2rem;
    margin-top: 1rem;
    width: 100%;
    max-width: 400px;
  }
  
  .forgot-password-box h2 {
    font-size: 2rem;
    margin-bottom: 1rem;
  }
  
  .forgot-password-box form {
    display: flex;
    flex-direction: column;
  }
  
  .forgot-password-box label {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
  }
  
  .forgot-password-box input {
    font-size: 1.2rem;
    padding: 0.5rem;
    margin-bottom: 1rem;
    width: 100%;
    outline: none;
    border: 1px solid #d32f2f;
    border-radius: 5px;
  }
  
  .forgot-password-box button {
    font-size: 1.2rem;
    padding: 0.5rem;
    cursor: pointer;
    background-color: #d32f2f;
    color: #fff;
    border: none;
    margin-bottom: 1rem;
  }
  
  .success-message {
    color: #4caf50;
    margin-bottom: 1rem;
  }
  
  .error-message {
    color: #f44336;
    margin-bottom: 1rem;
  }
  