.movie-quote-container {
    padding: 1.5rem;
    margin-top: 4rem;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 4rem;

  }
  
  .movie-quote-text {
    color: #fff;
    font-size: 2rem;
    text-align: center;
    font-style: italic;

   /*  display: flex;
    justify-content: center;fs  
    align-items: center;
    width: 600px;
    height: 450px;
    background-color: rgb(192, 69, 69);
    border-radius: 50%; */
  }
  
  .movie-quote-details {
    color: #ccc;
    font-size: 1.2rem;
    text-align: right;
    font-style: italic;
  }
  
  .movie-quote-details p {
    margin: 0;
    margin-top: -40px;
  }
  
  .movie-quote-details p:before {
    content: "-";
    margin-right: 10px;
  }

  

  