.members-container {
    background-color: #222;
    padding: 2rem;
  }
  
  .member-card {
    background-color: #333;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    color: #f0f0f0;
    width: 100%;
    border-bottom: 1px solid #333;
    overflow: hidden;
    border-bottom: 1px solid #333;
    padding: 1rem;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
  }

  .member-row {
    width: 100%;
  }
  
   
  .member-name {
     font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 0.25rem;
    color: white;
  }
  
  .member-location {
    font-size: 1rem;

  }
 
  .member-image {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    margin-right: 1rem;

  }
  
 
  
 
  