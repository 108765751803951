
  .games-container {
    display: block;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 20px;
  }
  
  
  .games-header {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    color: white;
    font-weight: bolder;
    font-size: 3.5rem;
    text-align: center;
    margin-top: 0.5rem;
    margin-bottom: 2rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    background-image: linear-gradient(rgba(236, 225, 133, 0.3), rgba(210, 208, 124, 0.3)), url('../../images/games.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    padding: 20px;
  }
  
  .game-card {
    padding: 20px;
    border: 2px solid yellow;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.1);
    text-align: center;
    cursor: pointer;
    width: 100%;
    max-width: 720px;
    margin: auto;
    margin-bottom: 30px;
  }
  
  .gameLink {
    font-size: 2em;
    color: yellow;
    text-decoration: none;
    margin-bottom: 10px;
  }
  
  .game-description {
    color: white;
    font-size: 1.2em;
  }
  
